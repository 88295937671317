import { useContext, useEffect, useRef, useState } from "react"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { RegisterProps } from "../../../models/RegisterProps"
import { AboutInitiative } from "../../components/AboutInitiative"
import { CongratsContent } from "../../components/CongratsContent"
import { FinancialContent } from "../../components/FinancialContent"
import { FirstInformationContent } from "../../components/FirstInformationContent"
import { InitialRegisterContent } from "../../components/InitialRegisterContent"
import { NetworksPartnersContent } from "../../components/NetworksPartnersContent"
import { SolidarityBankContent } from "../../components/SolidarityBankContent"
import { AdminPageContext } from "../../../context/AdminPageContext"
import { Container } from "./style"
import boxArrowInRight from "../../../assets/img/icons/box-arrow-in-right.svg"
import lockIcon from "../../../assets/img/icons/lock-fill.svg"
import { GlobalContext } from "../../../context/GlobalContext"
import { useRouteLoaderData } from "react-router"

type Props = {
    registerData?: RegisterProps
    isAdminPage?: boolean
    isInitialRegister?: boolean
    fetchData: () => void
}

export const RegisterPage = ({ isAdminPage, registerData, isInitialRegister, fetchData }: Props): JSX.Element => {
    const topElem = useRef<any>();

    const { setIsEdition } = useContext(AdminPageContext)
    const { isAuthenticated, lastStep, userData } = useContext(GlobalContext)

    const [currentPage, setCurrentPage] = useState(isAuthenticated && !isInitialRegister ? 'first-information' : 'cadastro-inicial')

    const registerSteps = [
        {
            stepId: 1,
            alias: 'first-information',
            name: 'Primeiras Informações'
        },
        {
            stepId: 2,
            alias: 'about-initiative',
            name: 'Sobre a Iniciativa'
        },
        {
            stepId: 3,
            alias: 'financial',
            name: 'Sustentabilidade Financeira'
        },
        {
            stepId: 4,
            alias: 'networks-partners',
            name: 'Redes e Parceiros'
        },
        {
            stepId: 5,
            alias: 'solidarity-bank',
            name: 'Banco da Solidariedade'
        }
    ]

    useEffect(() => {
        handleScroll()
    }, [currentPage])

    const isStep3OrganizationType1and4 = (stepId: number): boolean => stepId === 3 && [1, 4].includes(userData.tipo_organizacao_id as number)

    const handleScroll = () => {
        setTimeout(() => {
            topElem.current.scrollIntoView({ behavior: 'smooth' })
        }, 400)
    }

    const renderSwitch = () => {
        switch (currentPage) {
            case 'cadastro-inicial':
                return <InitialRegisterContent />

            case 'first-information':
                return <FirstInformationContent />

            case 'about-initiative':
                return <AboutInitiative />

            case 'financial':
                return <FinancialContent />

            case 'networks-partners':
                return <NetworksPartnersContent />

            case 'solidarity-bank':
                return <SolidarityBankContent />

            case 'congrats':
                return <CongratsContent />

            default:
                break;
        }
    }

    const changeStep = (alias: string): void => {
        setCurrentPage(alias)
    }

    return (
        <Container>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-1"></div>

                    <div className="col-md-10">
                        {isAdminPage &&
                            <div
                                className="back-btn"
                                onClick={() => {
                                    setIsEdition(false)
                                    fetchData()
                                }}
                            >
                                <img className="icon mr-8" src={boxArrowInRight} alt="box-arrow-in-right" /><span>Voltar</span>
                            </div>
                        }

                        {!['cadastro-inicial', 'congrats'].includes(currentPage) &&
                            <div className="register-steps">
                                {registerSteps.map((step, index: number) => (
                                    <>
                                        {(step.stepId !== 3 || isStep3OrganizationType1and4(step.stepId)) &&
                                            <div
                                                key={index}
                                                className={
                                                    `step${currentPage === step.alias ? ' current' : ''}` +
                                                    `${step.stepId > lastStep ? ' block' : ''}`
                                                }
                                                onClick={() => step.stepId > lastStep ? undefined : changeStep(step.alias)}
                                            >
                                                {step.stepId > lastStep &&
                                                    <div className="block-content">
                                                        <img src={lockIcon} alt="lock-icon" />
                                                    </div>
                                                }
                                                <div className="content">
                                                    <div className="circle flex-cc">
                                                        {[1, 4].includes(userData.tipo_organizacao_id as number) ? index + 1 : index < 2 ? index + 1 : index}
                                                    </div>
                                                    <div className="text">{step.name}</div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                        <RegisterPageContext.Provider value={{
                            setCurrentPage, handleScroll
                        }}>
                            {renderSwitch()}
                        </RegisterPageContext.Provider>
                    </div>

                    <div className="col-md-1"></div>
                </div>
            </div>

            <div className="bar-footer"></div>
        </Container>
    )
}