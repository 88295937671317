import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../designSystems/components/Button'
import { Container } from './style'
import illustraMap from '../../../assets/img/illustra-map.svg'
import bgImgHome from '../../../assets/img/bg-img-home.svg'
import fundacaoAbh from '../../../assets/img/fundacao-abh.svg'
import fundacaoMboi from '../../../assets/img/fundacao-mboi.svg'
import logotipoImgHome from '../../../assets/img/logotipo-img-home.svg'
import { getFrontHome } from '../../../api/utils'
import { GlobalContext } from '../../../context/GlobalContext'

export const HomePage = (): JSX.Element => {
    const [dataPage, setDataPage] = useState<any>({ ongs: 0, companies: 0, equipment: 0, entrepreneurs: 0 })
    const { setIsLoading, setDataToast, getErrorMessage, vertifyAuthenticatedRedirect } = useContext(GlobalContext)
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        getFrontHome().then((response: any) => {
            setIsLoading(false)
            const result: any = response?.data.data
            if (result.length) {
                setDataPage({
                    ...dataPage,
                    ongs: result[0].value,
                    companies: result[1].value,
                    equipment: result[2].value,
                    entrepreneurs: result[3].value
                })
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }, [])

    const goToPage = (path: string): void => {
        navigate(path)
    }

    return (
        <Container>
            <div className="home-page">
                <div className="container-fluid">
                    <div className="row margin-0">
                        <div className="col-md-6 padding-0">
                            <div className="left flex-cc">
                                <div className="content flex-cc">
                                    <div className="people-img">
                                        <img src={bgImgHome} alt="bg-img-home" />
                                    </div>

                                    <div className="info">
                                        <div className="info-container-logo">
                                            <div className="logo-link" onClick={() => goToPage('/mapa')} />
                                            <img src={logotipoImgHome} alt="logotipo-img-home" />
                                        </div>
                                        <div className="title">Bem-vindo!</div>
                                        <div className="description">
                                            Aqui você encontra a potência da periferia sul de São Paulo em um único lugar.
                                        </div>
                                        <div className="data-numbers">
                                            <div className="data-n data-1">
                                                <div className="number">{dataPage.ongs}</div>
                                                <div className="text">OSCs/Coletivos (formais e informais)</div>
                                            </div>
                                            <div className="data-n data-2">
                                                <div className="number">{dataPage.companies}</div>
                                                <div className="text">Empresas</div>
                                            </div>
                                            <div className="data-n data-3">
                                                <div className="number">{dataPage.equipment}</div>
                                                <div className="text">Equipamentos Públicos</div>
                                            </div>
                                            <div className="data-n data-4">
                                                <div className="number">{dataPage.entrepreneurs}</div>
                                                <div className="text">MEI/Empreendedores/Prestadores de Serviço</div>
                                            </div>
                                        </div>

                                        <Button
                                            hasShadow
                                            className="go-map radius-100 btn-gray"
                                            text={'Ir para o mapa'}
                                            onClick={() => goToPage('/mapa')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 padding-0">
                            <div className="right-content">
                                <div className="descriptionHome">
                                    <span className='row'>
                                        Aqui você A periferia sul é cheia de ações diversas e potentes e queremos mostrar isso para todo mundo!
                                    </span>
                                    <span className='row'>
                                        O Mapa PerifaSul é um mapa que quer mostrar todas as iniciativas, formais e informais, coletivos, organizações, empreendedores, prestadores de serviço e equipamentos públicos da região. Isso ajudará as pessoas a encontrarem o que precisam e a se conectarem umas com as outras.
                                    </span>
                                    <span className='row'>
                                        O mapa tem mais de opções de 30 opções de filtros e também um banco da solidariedade que mostra o que as organizações podem oferecer à comunidade.
                                        Se você lidera uma OSC ou coletivo, mas também tem uma MEI, você pode fazer dois (ou mais) cadastros para aparecer em mais filtros.
                                        Vamos juntos mostrar para todo mundo o que a periferia sul tem de bom!
                                    </span>
                                    <span className='row'>
                                        Entre no mapa, faça seu cadastro e compartilhe a ideia com sua rede.
                                    </span>
                                </div>
                            </div>
                            <div className="right-content flex-cc separador">
                                <img src={illustraMap} alt="home-map" />
                            </div>
                            <div className='row margin-top-20'>
                                <div className="col-md-12">
                                    <div className="display: flex; justify-content: center text-center">
                                        <img src={fundacaoAbh} alt="bg-img-home" />
                                        <img src={fundacaoMboi} alt="bg-img-home" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Container>
    )
}