import styled from "styled-components";
import { Variables } from "../../../styles/variables";

export const Container = styled.div`
    .home-page {
        .container-fluid {
            padding: 0px
        }

        .right,
        .left {
            height: 100vh;
        }

        .right {
            padding: 40px 0px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        
        .descriptionHome {
            position: relative;
            padding: 20px 30px 0px 50px;
        }

        .separador {
            padding: 40px 0px;
        }
        
        .left {
            background: ${Variables.yellow};

            .content {
                position: relative;
                padding-top: 179px;

                .people-img {
                    position: absolute;
                    top: 0px;
                    img {
                        width: 100%;
                    }
                }

                .info {
                    z-index: 11;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    max-width: 500px;
                    width: 100%;
                    height: 476px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 4px -4px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 24px;

                    .info-container-logo {
                        position: relative;

                        .logo-link {
                            cursor: pointer;
                            position: absolute;
                            width: 146px;
                            height: 53px;
                            top: 18px;
                            right: 0;
                        }
                    }

                    img {
                        width: 100%;
                    }

                    .title {
                        margin-top: 14px;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 28px;
                    }

                    .description {
                        width: 335px;
                        text-align: center;
                        margin-top: 14px;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                    }

                    .data-numbers {
                        margin: 8px 0px 24px;

                        .data-n {
                            display: flex;
                            align-items: center;
                            margin-top: 16px;

                            &.data-1 {
                                .number {
                                    background: #8C0D64;
                                    border: 0.5px solid #8C0D64;
                                }
                            }

                            &.data-2 {
                                .number {
                                    background: ${Variables.yellow};
                                    border: 0.5px solid ${Variables.yellow};
                                }
                            }

                            &.data-3 {
                                .number {
                                    background: #2E197E;
                                    border: 0.5px solid #2E197E;
                                }
                            }

                            &.data-4 {
                                .number {
                                    background: #006768;
                                    border: 0.5px solid #006768;
                                }
                            }

                            .number {
                                box-sizing: border-box;
                                color: #FFF;
                                font-weight: 600;
                                margin-right: 11px;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                padding: 3px 18px;
                                gap: 10px;
                                width: 53px;
                                height: 28px;
                                border-radius: 20px;
                            }

                            .text {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }

        .go-map {
            font-size: 16px;
        }
    }

    @media (max-width: 575.98px) {
        .home-page {
            .left {
                padding-bottom: 32px;

                .content {
                    margin: 20px;
                    
                    .people-img {
                        img {
                            width: 100%;
                        }
                    }

                    .info {
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .right {
                img {
                    width: 100%;
                }
            }
        }
    }
    
    @media (min-width: 575.99px) and (max-width: 767.98px) {}
    
    @media (min-width: 767.99px) and (max-width: 991.98px) {}
    
    @media (min-width: 991.99px) and (max-width: 1199.98px) {}
    
    @media (min-width: 1399.99px) {}
`