import { useContext, useEffect, useState } from "react"
import { Checkbox, Dropdown, Form, Popup, TextArea } from "semantic-ui-react"
import { Button } from "../../../designSystems/components/Button"
import { Container } from "./style"
import { FirstInformationProps } from "../../../models/FirstInformationProps"
import closeInputIcon from "../../../assets/img/icons/close-input.svg"
import ods1 from "../../..//assets/img/icons/ods/E-WEB-Goal-01.svg"
import ods2 from "../../..//assets/img/icons/ods/E-WEB-Goal-02.svg"
import ods3 from "../../..//assets/img/icons/ods/E-WEB-Goal-03.svg"
import ods4 from "../../..//assets/img/icons/ods/E-WEB-Goal-04.svg"
import ods5 from "../../..//assets/img/icons/ods/E-WEB-Goal-05.svg"
import ods6 from "../../..//assets/img/icons/ods/E-WEB-Goal-06.svg"
import ods7 from "../../..//assets/img/icons/ods/E-WEB-Goal-07.svg"
import ods8 from "../../..//assets/img/icons/ods/E-WEB-Goal-08.svg"
import ods9 from "../../..//assets/img/icons/ods/E-WEB-Goal-09.svg"
import ods10 from "../../..//assets/img/icons/ods/E-WEB-Goal-10.svg"
import ods11 from "../../..//assets/img/icons/ods/E-WEB-Goal-11.svg"
import ods12 from "../../..//assets/img/icons/ods/E-WEB-Goal-12.svg"
import ods13 from "../../..//assets/img/icons/ods/E-WEB-Goal-13.svg"
import ods14 from "../../..//assets/img/icons/ods/E-WEB-Goal-14.svg"
import ods15 from "../../..//assets/img/icons/ods/E-WEB-Goal-15.svg"
import ods16 from "../../..//assets/img/icons/ods/E-WEB-Goal-16.svg"
import ods17 from "../../..//assets/img/icons/ods/E-WEB-Goal-17.svg"
import { UploadImg } from "../../../designSystems/components/UploadImg"
import { RegisterPageContext } from "../../../context/RegisterPageContext"
import { GlobalContext } from "../../../context/GlobalContext"
import { SubmitHandler, useForm, Controller } from "react-hook-form"
import { withMask } from 'use-mask-input';
import { createFirstInfo, getFirstInfo, updateFirstInfo } from "../../../api/first-info"
import { getAddress, getStepGranted } from "../../../api/utils"
import * as _ from 'lodash'
import { useNavigate } from "react-router"
import { coverageAreaOtions, initiativeIsOtions, legalFormatOtions } from "../../../api/utils/fieldsOptions"

const areYouOtions = [
    { key: 's', text: 'Selecione ...', value: undefined },
    { key: 'ong', text: 'Um OSCs/Coletivos (formais e informais)', value: 1 },
    { key: 'empresa', text: 'Uma empresa', value: 2 },
    { key: 'equipamento', text: 'Um equipamento público', value: 3 },
    { key: 'prestador', text: 'MEI/Empreendedores/Prestadores de Serviço', value: 4 }
]

const defaultOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Sim', value: 1 },
    { key: 2, text: 'Não', value: 2 },
]

const initiativeNatureOtions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Advocacy', value: 1 },
    { key: 2, text: 'Apoio', value: 2 },
    { key: 3, text: 'Assistencial', value: 3 },
    { key: 4, text: 'Equipamento público', value: 4 },
    { key: 5, text: 'Formação', value: 5 },
    { key: 6, text: 'Pesquisa', value: 6 },
    { key: 7, text: 'Venda de Produtos', value: 7 },
    { key: 8, text: 'Prestação de Serviços', value: 8 },
    { key: 9, text: 'Outro (especificar)', value: 9 },
]

const odsOptions = [
    { key: 100, text: 'Erradicação da Pobreza', icon: ods1 },
    { key: 200, text: 'Fome Zero e Agricultura Sustentável', icon: ods2 },
    { key: 300, text: 'Saúde e Bem-Estar', icon: ods3 },
    { key: 400, text: 'Educação de Qualidade', icon: ods4 },
    { key: 500, text: 'Igualdade de Gênero', icon: ods5 },
    { key: 600, text: 'Água Potável e Saneamento', icon: ods6 },
    { key: 700, text: 'Energia Limpa e Acessível', icon: ods7 },
    { key: 800, text: 'Trabalho Decente e Crescimento Econômico', icon: ods8 },
    { key: 900, text: 'Indústria, Inovação e Infraestrutura', icon: ods9 },
    { key: 1010, text: 'Redução das Desigualdades', icon: ods10 },
    { key: 1110, text: 'Cidades e Comunidades Sustentáveis', icon: ods11 },
    { key: 1210, text: 'Consumo e Produção Responsáveis', icon: ods12 },
    { key: 1310, text: 'Ação Contra a Mudança Global do Clima', icon: ods13 },
    { key: 1410, text: 'Vida na Água', icon: ods14 },
    { key: 1510, text: 'Vida Terrestre', icon: ods15 },
    { key: 1610, text: 'Paz, Justiça e Instituições Eficazes', icon: ods16 },
    { key: 1710, text: 'Parcerias e Meios de Implementação', icon: ods17 },
]

const areasExpertiseOptions = [
    { key: 100, text: 'Ações Afirmativas: raça, gênero e protagonismo jovem' },
    { key: 200, text: 'Advocacy & Incidência Política' },
    { key: 300, text: 'Arte & Cultura' },
    { key: 400, text: 'Assistência Social' },
    { key: 500, text: 'Bem Viver & Qualidade de Vida' },
    { key: 600, text: 'Comunicação & Marketing' },
    { key: 700, text: 'Defesa de direitos da população LGBTQIAP+' },
    { key: 800, text: 'Defesa de direitos da população negra' },
    { key: 900, text: 'Defesa de direitos das mulheres' },
    { key: 1010, text: 'Defesa de direitos dos povos indígenas' },
    { key: 1110, text: 'Desenvolvimento Comunitário' },
    { key: 1210, text: 'Direito dos Animais' },
    { key: 1310, text: 'Direitos humanos, justiça social e cidadania' },
    { key: 1410, text: 'Economia criativa e solidária' },
    { key: 1510, text: 'Educação' },
    { key: 1610, text: 'Esporte & Saúde' },
    { key: 1710, text: 'Habitação' },
    { key: 1810, text: 'Inclusão Digital' },
    { key: 1910, text: 'Inclusão Produtiva (empreendedorismo, empregabilidade e capacitação para o mercado de trabalho, geração de trabalho e renda)' },
    { key: 2010, text: 'Justiça Racial' },
    { key: 2110, text: 'Meio ambiente / Sustentabilidade' },
    { key: 2210, text: 'Mobilidade Urbana' },
    { key: 2310, text: 'Paz & Justiça' },
    { key: 2410, text: 'PCD - Pessoas com Deficiência' },
    { key: 2510, text: 'Pesquisa / Produção de Conhecimento' },
    { key: 2610, text: 'Refugiados' },
    { key: 2710, text: 'Saúde' },
    { key: 2810, text: 'Tecnologias Sociais' },
    { key: 2910, text: 'Outro (especifique)' },
]

const cityActionsOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'São Paulo', value: 1 },
    { key: 2, text: 'Taboão da Serra', value: 2 },
    { key: 3, text: 'Embu das Artes', value: 3 },
    { key: 4, text: 'Embu Guaçu', value: 4 },
    { key: 5, text: 'São Bernardo do Campo', value: 1 },
    { key: 6, text: 'Diadema', value: 6 },
    { key: 8, text: 'Itapecerica da Serra', value: 8 },
    { key: 9, text: 'Todas', value: 9 },
]

const districtLocaleOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 100, text: 'Cambuci', value: 100 },
    { key: 200, text: 'Campo Belo', value: 200 },
    { key: 300, text: 'Campo Grande', value: 300 },
    { key: 400, text: 'Campo Limpo', value: 400 },
    { key: 500, text: 'Capão Redondo', value: 500 },
    { key: 600, text: 'Cidade Ademar', value: 600 },
    { key: 700, text: 'Cidade Dutra', value: 700 },
    { key: 800, text: 'Cursino', value: 800 },
    { key: 900, text: 'Grajaú', value: 900 },
    { key: 1010, text: 'Ipiranga', value: 1010 },
    { key: 1110, text: 'Itaim Bibi', value: 1110 },
    { key: 1210, text: 'Itaim Paulista', value: 1210 },
    { key: 1310, text: 'Jabaquara', value: 1310 },
    { key: 1410, text: 'Jardim Ângela', value: 1410 },
    { key: 1510, text: 'Jardim Paulista', value: 1510 },
    { key: 1610, text: 'Jardim São Luís', value: 1610 },
    { key: 1710, text: 'Marsilac', value: 1710 },
    { key: 1810, text: 'Moema', value: 1810 },
    { key: 1910, text: 'Morumbi', value: 1910 },
    { key: 2010, text: 'Parelheiros', value: 2010 },
    { key: 2110, text: 'Pedreira', value: 2110 },
    { key: 2210, text: 'Sacomã', value: 2210 },
    { key: 2310, text: 'Santo Amaro', value: 2310 },
    { key: 2410, text: 'Saúde', value: 2410 },
    { key: 2510, text: 'Socorro', value: 2510 },
    { key: 2610, text: 'Vila Andrade', value: 2610 },
    { key: 2710, text: 'Vila Mariana', value: 2710 },
    { key: 2810, text: 'Vila Sônia', value: 2810 },
]

const whatIdoOptions = [
    { key: 'none', text: 'Selecione ...', value: undefined },
    { key: 1, text: 'Presto serviços', value: 1 },
    { key: 2, text: 'Vendo produtos', value: 2 },
]

type Props = {
    dataProps?: FirstInformationProps
}

export const FirstInformationContent = ({ dataProps }: Props): JSX.Element => {
    const [dataPage, setDataPage] = useState<FirstInformationProps>({} as FirstInformationProps)
    const [dataPageTemp, setDataPageTemp] = useState<any>({} as any)
    const [updateAction, setUpdateAction] = useState(false)
    const [logoFile, setLogoFile] = useState<any>(undefined);
    const [tipoOrganizacao, setTipoOrganizacao] = useState<any>(undefined);
    const [permissaosId, setPermissaosId] = useState<any>(false);
    const [cityActions, setCityActions] = useState<any>(undefined);
    const [districtLocale, setDistrictLocale] = useState<any>(undefined);
    const [districtLocaleSelected, setDistrictLocaleSelected] = useState<any>([])

    const [odsValues, setOdsValues] = useState<number[]>(dataProps?.ods || [])
    const [areasExpertise, setAreasExpertise] = useState<number[]>([])
    const [initiativeNatureOtherShow, setInitiativeNatureOtherShow] = useState(false)
    const [legalFormatOtherShow, setLegalFormatOtherShow] = useState(false)
    const [areasExpertiseOtherShow, setAreasExpertiseOtherShow] = useState(false)

    const { setCurrentPage, handleScroll } = useContext(RegisterPageContext)
    const { setIsLoading, setDataToast, changeLastStep, isAuthenticated, getErrorMessage, vertifyAuthenticatedRedirect, userId, setUserId, userData, setUserData, showNextRegisterButton } = useContext(GlobalContext)

    const aliasInfo = userData.permissaos_id === 3 && !userData.cpf ? 'usuario-temp' : 'first-info'

    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm<FirstInformationProps>(
        {
            defaultValues: {
                whatIdo: undefined,
                initiativeNature: undefined,
                initiativeIs: undefined,
                legalFormat: undefined,
                ods: [],
                areasExpertise: [],
                cityActions: undefined,
                districtLocale: [],
                cep: undefined,
                address: undefined,
                number: undefined,
                complement: undefined,
                burgh: undefined,
                district: undefined,
                city: undefined,
                state: undefined,
                phone: undefined,
                email: undefined,
                coverageArea: undefined,
                hasCnpj: undefined,
                initiativeName: undefined,
                logo: undefined,
                initiativeDescription: undefined,
                initiativeNatureOther: undefined,
                legalFormatOther: undefined,
                areasExpertiseOther: undefined,
            }
        }
    );

    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated && !(userData.permissaos_id === 3 && !userData.cpf)) {
            setTipoOrganizacao(userData.tipo_organizacao_id)
            setPermissaosId(userData.permissaos_id)
            fetchData()
        } else {
            const localInfoTemp: FirstInformationProps = JSON.parse(localStorage.getItem(aliasInfo) || '{}')
            setDataPageTemp(localInfoTemp)
            setTipoOrganizacao(localInfoTemp.tipoOrganizacao)

            if (aliasInfo === 'usuario-temp') {
                const localUserInfo: any = JSON.parse(localStorage.getItem('usuario') || '{}')
                setValue('email', localUserInfo.user.email)
            }
        }
    }, [])

    const fetchData = (): void => {
        setIsLoading(true)

        getFirstInfo(pathnameIsAdmin() ? { user_id: userId } : null).then((response: any) => {
            // setIsLoading(false)

            if (response.data.data.length) {
                setUpdateAction(true)
            }

            const responseData: FirstInformationProps = response.data.data[0]
            setDataPage(responseData)
            fillForm(responseData)

            if (!pathnameIsAdmin()) {
                fetchStepGranted()
            } else {
                setIsLoading(false)
            }
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const pathnameIsAdmin = (): boolean => {
        return window.location.pathname === '/admin'
    }

    const fetchStepGranted = (): void => {
        getStepGranted().then((response: any) => {
            setIsLoading(false)
            const result: any[] = response?.data?.data
            changeLastStep(result.length ? _.max(result.map(item => item.step)) : 0)
        }).catch(err => {
            setIsLoading(false)
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao buscar informações' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const goToPage = (path: string): void => {
        navigate(path)
    }

    const fillForm = (data: FirstInformationProps): void => {
        setValue('whatIdo', data.whatIdo)
        setValue('tipoOrganizacao', dataPageTemp.tipoOrganizacao || userData.tipo_organizacao_id)
        setValue('initiativeNature', data.initiativeNature)
        setValue('initiativeIs', data.initiativeIs)
        setValue('legalFormat', data.legalFormat)

        setValue('ods', data.ods)
        setOdsValues(data.ods || [])

        setValue('areasExpertise', data.areasExpertise)
        setAreasExpertise(data.areasExpertise || [])

        setValue('cityActions', data.cityActions)
        setCityActions(data.cityActions)

        setValue('districtLocale', data.districtLocale)
        setDistrictLocaleSelected(data.districtLocale)

        setValue('cep', data.cep)
        setValue('address', data.address)
        setValue('number', data.number)
        setValue('complement', data.complement)
        setValue('burgh', data.burgh)
        setValue('district', data.district)
        setValue('city', data.city)
        setValue('state', data.state)
        setValue('phone', data.phone)
        setValue('email', data.email)
        setValue('coverageArea', data.coverageArea)
        setValue('hasCnpj', data.hasCnpj)
        setValue('initiativeName', data.initiativeName)
        setValue('initiativeDescription', data.initiativeDescription)

        setValue('logo', data.logo)
        setLogoFile(data.logo)

        setValue('initiativeNatureOther', data.initiativeNatureOther)
        setInitiativeNatureOtherShow(data.initiativeNature === 9)

        setValue('legalFormatOther', data.legalFormatOther)
        setLegalFormatOtherShow(data.legalFormat === 7)

        setValue('areasExpertiseOther', data.areasExpertiseOther)
        setAreasExpertiseOtherShow(data.areasExpertise?.includes(2910) || false)
    }

    const onChangeOds = (checked: boolean | undefined, key: number): number[] => {
        let odsList: number[] = (odsValues.length < 3) ? [...odsValues] : []

        if (checked) {
            odsList.push(key)
        } else {
            odsList = odsList.filter(k => k !== key)
        }

        setOdsValues(odsList)
        return odsList
    }

    const defaultCheckedOds = (value: number): boolean => {
        return odsValues?.length ? odsValues?.includes(value) : false
    }

    const onChangeAreasExpertise = (checked: boolean | undefined, key: number): number[] => {
        let areasExpertiseList: any[] = [];

        if (checked) {
            areasExpertiseList.push(key)
        } else {
            areasExpertiseList = areasExpertiseList.filter(k => k !== key)
        }

        setAreasExpertise(areasExpertiseList)
        setAreasExpertiseOtherShow(areasExpertiseList.includes(2910))
        if (!areasExpertiseList.includes(2910)) {
            setValue('areasExpertiseOther', '')
        }
        return areasExpertiseList
    }

    const defaultCheckedAreasExpertise = (value: number): boolean => {
        return areasExpertise?.length ? areasExpertise?.includes(value) : false
    }

    const removeDistrictLocationItem = (value: number): void => {
        const result = districtLocaleSelected.filter((d: number) => d !== value)
        setDistrictLocaleSelected(result)
        setValue('districtLocale', result)
    }

    const create = (dataForm: FirstInformationProps): void => {
        setIsLoading(true)
        createFirstInfo(dataForm).then((data: any) => {
            setIsLoading(false)
            localStorage.removeItem(aliasInfo)
            const result: any = data.data
            const storageData: any = {
                authorisation: result['authorisation'],
                user: { ...result['data'], id: result['data'].user_id, permissaos_id: 3, tipo_organizacao_id: dataForm.tipoOrganizacao, name: dataForm.name, receiveAdvertisement: 1, status: "1", is_admin: false },
                status: result['status']
            }

            localStorage.setItem(data.is_admin ? 'authentication-admin' : 'usuario', JSON.stringify(storageData))
            setUserData(storageData.user)
            setCurrentPage('about-initiative')
            setUserId(result['data'].user_id)
            changeLastStep(2)
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const update = (dataForm: FirstInformationProps): void => {
        setIsLoading(true)
        updateFirstInfo(dataForm).then((data: any) => {
            setIsLoading(false)
            setCurrentPage('about-initiative')
        }).catch(err => {
            setIsLoading(false)
            handleScroll()
            const textError = err.response.data.message === 'Unauthorized' ? 'Erro ao salvar' : getErrorMessage(err.response.data.message)
            setDataToast({ show: true, text: textError })
            if (vertifyAuthenticatedRedirect(err.response.data.message)) {
                goToPage('/')
            }
        })
    }

    const onSubmit: SubmitHandler<FirstInformationProps> = data => {
        const localFirstInfo: Props = JSON.parse(localStorage.getItem(aliasInfo) || '{}')

        const dataForm = {
            ...dataPage,
            ...data,
            ...localFirstInfo,
            whatIdo: data.whatIdo || 0,
            logo: logoFile || ''
        }

        if (cityActions === 1 && !districtLocaleSelected.length) {
            handleScroll()
            return
        }

        updateAction ? update(dataForm) : create(dataForm)
    }

    const getAddressByCep = (cep: string) => {
        if (!cep.includes('_')) {
            setIsLoading(true)
            getAddress(cep).then((response: any) => {
                setIsLoading(false)
                if (response.data.data.length) {
                    const address: any = _.first(response.data.data)
                    setValue('address', address.address)
                    setValue('burgh', address.burgh)
                    setValue('city', address.city)
                    setValue('state', address.state)
                    setValue('number', '')
                    setValue('complement', '')
                    setValue('district', '')

                } else {
                    setValue('address', '')
                    setValue('burgh', '')
                    setValue('city', '')
                    setValue('state', '')
                    setValue('number', '')
                    setValue('complement', '')
                    setValue('district', '')
                }
            }).catch(err => {
                setIsLoading(false)
            })
        }
    }

    return (
        <Container
            count={districtLocaleSelected.length}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4">
                        <Controller
                            name="tipoOrganizacao"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        disabled={permissaosId === 200 ? false : true}
                                        size="huge"
                                        label='Você é: *'
                                        placeholder='Selecione ...'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                        options={areYouOtions}
                                    />
                                </>
                            )}
                        />
                    </div>

                    {userData.tipo_organizacao_id === 4 &&
                        <div className="col-md-4">
                            <Controller
                                name="whatIdo"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Select
                                            fluid
                                            size="huge"
                                            label='O que faço? *'
                                            placeholder='Selecione ...'
                                            className={errors.whatIdo && 'field-error'}
                                            value={value}
                                            options={whatIdoOptions}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        />
                                        {errors.whatIdo && <p className="field-error-msg">Campo obrigatório</p>}
                                    </>
                                )}
                            />
                        </div>
                    }
                    <div className="col-md-4">
                        <Controller
                            name="initiativeNature"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Qual é a natureza da sua iniciativa? *'
                                        placeholder='Selecione ...'
                                        className={errors.initiativeNature && 'field-error'}
                                        value={value}
                                        options={initiativeNatureOtions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            setValue('initiativeNatureOther', '')
                                            setInitiativeNatureOtherShow(value === 9)
                                        }}
                                    />
                                    {errors.initiativeNature && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    {initiativeNatureOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="initiativeNatureOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('initiativeNatureOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }
                    <div className="col-md-4">
                        <Controller
                            name="initiativeIs"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Sua iniciativa é: *'
                                        placeholder='Selecione ...'
                                        className={errors.initiativeIs && 'field-error'}
                                        value={value}
                                        options={initiativeIsOtions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.initiativeIs && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="legalFormat"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Qual é o seu formato jurídico? *'
                                        placeholder='Selecione ...'
                                        className={errors.legalFormat && 'field-error'}
                                        value={value}
                                        options={legalFormatOtions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            setValue('legalFormatOther', '')
                                            setLegalFormatOtherShow(value === 7)
                                        }}
                                    />
                                    {errors.legalFormat && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    {legalFormatOtherShow &&
                        <div className="col-md-4">
                            <Controller
                                name="legalFormatOther"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Form.Input
                                            fluid
                                            size="huge"
                                            label='s'
                                            className="text-white"
                                            placeholder='Outro (especificar)'
                                            value={value}
                                            onChange={function (e, { value }) {
                                                onChange(value)
                                            }}
                                        >
                                            <input />
                                            <div
                                                className="img-icon flex-cc"
                                                onClick={() => {
                                                    onChange(undefined)
                                                    setValue('legalFormatOther', '')
                                                }}>
                                                <img src={closeInputIcon} alt="close-input" />
                                            </div>
                                        </Form.Input>
                                    </>
                                )}
                            />
                        </div>
                    }
                </div>

                <div className="row ods-options">
                    <div className="fw-600 mb-16 fc-black">Com quais ODSs (Objetivos do Desenvolvimento Sustentável) sua iniciativa está relacionada? (selecione até 3) *</div>

                    <Controller
                        name="ods"
                        control={control}
                        rules={{ required: !odsValues.length || odsValues.length > 3 }}
                        render={({ field: { onChange, value } }) => (
                            <>
                                {odsOptions.map((o, index) => (
                                    <div className="col-md-4" key={index}>
                                        <Form.Checkbox
                                            key={o.key}
                                            className="mb-0"
                                            label={<label><img src={o.icon} /><div className="text">{o.text}</div></label>}
                                            checked={defaultCheckedOds(o.key)}
                                            onChange={function (e, { checked }) {
                                                onChange(onChangeOds(checked, o.key))
                                            }}
                                        />
                                    </div>
                                ))}
                                {errors.ods?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                {odsValues.length > 3 && <p className="field-error-msg">Selecione até 3 opções</p>}
                            </>
                        )}
                    />
                </div>

                <div className="row">
                    <div className="fw-600 mb-16 fc-black">Qual é a área de atuação da sua iniciativa? (selecione apenas 1) *</div>

                    <Controller
                        name="areasExpertise"
                        control={control}
                        rules={{ required: !areasExpertise.length || areasExpertise.length > 1 }}
                        render={({ field: { onChange, value } }) => (
                            <>
                                {areasExpertiseOptions.map((o, index) => (
                                    <div className="col-md-4" key={index}>
                                        <Form.Checkbox
                                            key={o.key}
                                            className="mb-0"
                                            label={o.text}
                                            checked={defaultCheckedAreasExpertise(o.key)}
                                            onChange={function (e, { checked }) {
                                                onChange(onChangeAreasExpertise(checked, o.key))
                                            }}
                                        />
                                    </div>
                                ))}

                                {areasExpertiseOtherShow &&
                                    <div className="col-md-4">
                                        <Controller
                                            name="areasExpertiseOther"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <Form.Input
                                                        fluid
                                                        size="huge"
                                                        label='s'
                                                        className="text-white"
                                                        placeholder='Outro (especificar)'
                                                        value={value}
                                                        onChange={function (e, { value }) {
                                                            onChange(value)
                                                        }}
                                                    >
                                                        <input />
                                                        <div
                                                            className="img-icon flex-cc"
                                                            onClick={() => {
                                                                onChange(undefined)
                                                                setValue('areasExpertiseOther', '')
                                                            }}>
                                                            <img src={closeInputIcon} alt="close-input" />
                                                        </div>
                                                    </Form.Input>
                                                </>
                                            )}
                                        />
                                    </div>
                                }

                                {errors.areasExpertise?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                {areasExpertise.length > 1 && <p className="field-error-msg">Selecione apenas 1 opção</p>}
                            </>
                        )}
                    />
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Controller
                            name="cityActions"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Em qual cidade sua iniciativa desenvolve ações? *'
                                        placeholder='Selecione ...'
                                        className={errors.cityActions && 'field-error'}
                                        value={value}
                                        options={cityActionsOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            setCityActions(value)

                                            if (value !== 1) {
                                                setDistrictLocaleSelected([])
                                                setValue('districtLocale', [])
                                            }
                                        }}
                                    />
                                    {errors.cityActions && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>

                    {cityActions === 1 &&
                        <div className="col-md-4">
                            <label className="label-form-default">Em qual distrito sua iniciativa está localizada? *</label>
                            <div className={districtLocaleSelected.length ? '' : 'field field-error'}>
                                <Dropdown
                                    placeholder='Selecione ...'
                                    fluid
                                    multiple
                                    search
                                    selection
                                    clearable
                                    options={districtLocaleOptions}
                                    value={districtLocaleSelected}
                                    onChange={function (e, { value }) {
                                        setDistrictLocaleSelected(value)
                                        setValue('districtLocale', value as number[])
                                    }}
                                >
                                    <Dropdown.Menu>
                                        {districtLocaleOptions.slice(1).map(op => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    let resValues = []

                                                    if (districtLocaleSelected.includes(op.key)) {
                                                        resValues = districtLocaleSelected.filter((item: number) => item !== op.key)
                                                    } else {
                                                        resValues = [...districtLocaleSelected, op.key]
                                                    }

                                                    setDistrictLocaleSelected(resValues)
                                                    setValue('districtLocale', resValues as number[])
                                                }}
                                            >
                                                <Checkbox
                                                    label={op.text}
                                                    checked={districtLocaleSelected.includes(op.key)}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {!districtLocaleSelected.length && <p className="field-error-msg">Campo obrigatório</p>}
                        </div>
                    }
                </div>

                <div className="row">
                    <div className="fw-600 mb-16 fc-black">Onde a sua iniciativa está localizada? Mesmo que você não tenha sede, é importante indicar um local em que está situada para que possamos inserir você no mapa) *</div>

                    <div className="col-md-4">
                        <Controller
                            name="cep"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='99999-999'
                                        className={errors.cep && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                            getAddressByCep(value)
                                        }}
                                    >
                                        <label className="label-inside">CEP *</label>
                                        <input ref={withMask('99999-999')} />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('cep', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.cep && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-8">
                        <Controller
                            name="address"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Rua/Alameda/Avenida/Travessa...'
                                        className={errors.address && 'field-error'}
                                        value={value}
                                        disabled
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Endereço *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('address', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.address && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="number"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Escreva o número'
                                        className={errors.number && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Número *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('number', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.number && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="complement"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Apto/Casa'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Complemento</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('complement', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="burgh"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        disabled
                                        size="huge"
                                        placeholder='Escreva o bairro'
                                        className={errors.burgh && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Bairro *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('burgh', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.burgh && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="district"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='Escreva o distrito'
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Distrito</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('district', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        disabled
                                        size="huge"
                                        placeholder='Escreva a cidade'
                                        className={errors.city && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Cidade *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('city', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.city && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="state"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        disabled
                                        size="huge"
                                        placeholder='Escreva o estado'
                                        className={errors.state && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Estado *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('state', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.state && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="phone"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        placeholder='(99) 99999-9999'
                                        className={errors.phone && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Telefone *</label>
                                        <input ref={withMask('(99) 99999-9999')} />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('phone', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.phone && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        type="email"
                                        placeholder='Escreva o e-mail institucional'
                                        className={errors.email && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">E-mail *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('email', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.email?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                    {errors.email?.type === 'pattern' && <p className="field-error-msg">E-mail inválido</p>}
                                </>
                            )}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="coverageArea"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Qual é a área de abrangência de atuação da sua iniciativa? *'
                                        placeholder='Selecione ...'
                                        className={errors.coverageArea && 'field-error'}
                                        value={value}
                                        options={coverageAreaOtions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.coverageArea && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="hasCnpj"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Select
                                        fluid
                                        size="huge"
                                        label='Sua iniciativa possui CNPJ? *'
                                        placeholder='Selecione ...'
                                        className={errors.hasCnpj && 'field-error'}
                                        value={value}
                                        options={defaultOptions}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    />
                                    {errors.hasCnpj && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="col-md-4 field-col-align">
                        <Controller
                            name="initiativeName"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <Form.Input
                                        fluid
                                        size="huge"
                                        label='Qual é o nome da sua iniciativa? *'
                                        placeholder='Escreva o nome da iniciativa'
                                        className={errors.initiativeName && 'field-error'}
                                        value={value}
                                        onChange={function (e, { value }) {
                                            onChange(value)
                                        }}
                                    >
                                        <label className="label-inside">Nome *</label>
                                        <input />
                                        <div
                                            className="img-icon flex-cc"
                                            onClick={() => {
                                                onChange(undefined)
                                                setValue('initiativeName', '')
                                            }}>
                                            <img src={closeInputIcon} alt="close-input" />
                                        </div>
                                    </Form.Input>
                                    {errors.initiativeName && <p className="field-error-msg">Campo obrigatório</p>}
                                </>
                            )}
                        />
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-4 order-1 order-md-0">
                        <UploadImg
                            label="Adicione a logo da sua iniciativa:"
                            logoUrl={logoFile}
                            changeLogoFile={setLogoFile}
                        />
                    </div>
                    <div className="col-md-8 order-0 order-md-1">
                        <label className="label-textarea">Descreva sua iniciativa em até 500 caracteres: *</label>
                        <div className="field">
                            <label className="label-outside">Descrição</label>
                            <div>
                                <Controller
                                    name="initiativeDescription"
                                    control={control}
                                    rules={{ required: true, minLength: 10, maxLength: 500 }}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <TextArea
                                                placeholder='Seja sucinto, mas capriche! É essa descrição aparecerá aos que acessarem seu perfil'
                                                value={value}
                                                className={errors.initiativeDescription && 'field-error'}
                                                onChange={function (e, { value }) {
                                                    onChange(value)
                                                }}
                                            >
                                            </TextArea>

                                            <p style={{ marginTop: '14px', marginBottom: '0px' }}>Caracteres: {(value?.length || 0)} / 500</p>
                                            {errors.initiativeDescription?.type === 'required' && <p className="field-error-msg">Campo obrigatório</p>}
                                            {errors.initiativeDescription?.type === 'minLength' && <p className="field-error-msg">Mínimo de 10 caracteres</p>}
                                            {errors.initiativeDescription?.type === 'maxLength' && <p className="field-error-msg">Máximo de 500 caracteres</p>}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <hr />
                    <div className="btn-actions f-right">
                        {localStorage.getItem(aliasInfo) &&
                            <Button
                                text="Anterior"
                                className="radius-100 btn-outline-gray mr-32"
                                onClick={() => setCurrentPage('cadastro-inicial')}
                            />
                        }
                        {(localStorage.getItem(aliasInfo) || showNextRegisterButton(updateAction)) &&
                            <Button
                                type="submit"
                                text="Próxima"
                                className="radius-100"
                                onClick={() => Object.keys(errors).length && handleScroll()}
                            />
                        }
                    </div>
                </div>
            </Form>
        </Container >
    )
}